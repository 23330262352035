import React from "react";
import styles from "./layout.module.scss";
import classnames from "classnames";

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const LayoutGroup = (props: Props) => {
  const { children, className, style } = props;
  const classes = classnames(styles.layoutStack, "layout-stack", className);
  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

export default LayoutGroup;
