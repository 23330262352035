import React from "react";
import LayoutGroup from "@/components/Layout/layout-group";
import Components from "@/components/components";
import SbEditable from "storyblok-react";
import classnames from "classnames";
import styles from "./layout.module.scss";
import { LayoutGroupStoryblok } from "@/types/component-types-sb";

interface Props {
  blok: LayoutGroupStoryblok;
  className?: string;
}

const SbLayoutGroup = (props: Props) => {
  const {
    blok: {
      align_horizontal,
      align_vertical,
      content,
      grid_line_start,
      grid_line_end,
    },
    className,
  } = props;
  const vars = {
    ["--align" as string]: align_horizontal,
    ["--justify" as string]: align_vertical,
    ["--col-start" as string]: grid_line_start,
    ["--col-end" as string]: grid_line_end,
  };
  return (
    // @ts-expect-error
    <SbEditable content={props.blok}>
      <LayoutGroup style={vars} className={className}>
        {content?.map((blok) => {
          return React.createElement(Components(blok.component), {
            key: blok._uid,
            className: classnames(
              styles.layoutItem,
              styles[blok.space_above],
              styles[blok.space_above_l],
              styles[blok.component]
            ),
            blok,
          });
        })}
      </LayoutGroup>
    </SbEditable>
  );
};

export default SbLayoutGroup;
